@font-face {
    font-family: "TradeGothicNextLTPro";
    src: local("TradeGothicNextLTPro"),
        url("../fonts//Linotype\ -\ Trade\ Gothic\ Next\ LT\ Pro\ Light.ttf") format("truetype");
        font-weight: light;
}
/* @font-face {
    font-family: "TradeGothicNextLTPro";
    src: local("TradeGothicNextLTPro"),
        url("../fonts//TradeGothicNextLTPro-Bd.woff2") format("truetype");
        font-weight: bold;
} */