.nav-link a {
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    font-weight: 900;
}
.nav-link a:hover {
    color: #8c4ccd;
}



@media only screen and (max-width: 767px) {
  .navbar-brand {
    width: 75%;
    margin-right: 0;
  }
  .navbar-toggler{
      border: 0;
  }
}
