.g-t-d {
  color: #131eef;
  text-decoration: none;
  font-weight: 600;
}
.g-t-d:focus-visible {
  outline: 0;
}
.g-t-d:hover{
    text-decoration: none;
}
