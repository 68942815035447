@import url("./colorConstant.css");
.form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.d-Form {
  display: flex;
  margin: 15px 0px 0px 0px;
}
form.d-Form input {
  border-radius: 0;
}
.d-button {
  background: var(--orange);
  border-radius: 0;
  color: var(--white);
  font-size: 14px;
}

.wd {
  width: 80%;
}

@media only screen and (max-width: 767px) {
  .d-button {
    padding: 5px;
    font-size: 11px;
  }
  .wd {
    width: 70%;
  }
}
