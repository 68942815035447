.signupText{
  font-size:29px;
  font-weight: 900;
  margin-top: 5%;
}
.helpText{
  font-size:26px;
  width: 80%;  
  font-weight: 700;
  margin-top: 5%;
}
.s-control{
  width: 80%;
}
 @media only screen and (max-width: 576px) {
  .signupText{
    font-size: 18px;
  }
} 