@import url("./colorConstant.css");

.location-box {
  margin-top: 20px;
  min-height: 100vh;
}
.r-t-6 {
  display: flex;
  justify-content: space-around;
}
.r-t-7 {
  flex-direction: column;
}

#map {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#accordion{
  max-height: 500px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#accordion::-webkit-scrollbar {
  display: none;
}

/* mediaQueries */

@media only screen and (max-width: 767px) {
  #accordion {
    order: 2;
  }
  #map {
    order: 1;
  }
  #signup {
    order: 3;
  }
}
