@import url("./colorConstant.css");

.header-top-img {
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.header-top-text {
  padding: 10%;
  text-align: center;
  width: 100%;
}
.primary-text {
  color: var(--white);
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 3.5rem;
}

.primary-text-locationname {
  color: var(--white);
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.secondary-text {
  color: var(--white);
  font-weight: lighter;
}
.header-top-img img {
  width: 100%;
  max-height: 400px;
}

.portland {
  background-image: url("../images/Portland\ background.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.tuscon {
  background-image: url("../images//6.99\ -\ Tucson.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.austin {
  background-image: url("../images/Austing\ Tx\ background.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.boston {
  background-image: url("../images/Boston\ background.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.indianapolis {
  background-image: url("../images/9.99\ -\ Indiana\ \(2\).jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.richmond {
  background-image: url("../images/9.99\ -\ Richmond\,\ Virginia\ \(2\).jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.Jacksonville {
  background-image: url("../images/12.99\ –\ Florida\ \(2\).jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.stlouis {
  background-image: url("../images/12.99\ –\ Missouri\ \(2\).jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.dn{
  display: none;
}
.ds{
  display: visible;
}

@media only screen and (max-width: 576px) {
  .primary-text {
    font-size: 14px;
  }
  .secondary-text {
    font-size: 12px;
  }
  .portland {
    background-size: cover;
  }
  .tuscon {
    background-size: cover;
  }
  .header-top-text {
    padding: 0;
  }
}
