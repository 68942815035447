@import url("./colorConstant.css");

.map-circle {
  height: 25px;
  width: 25px;
  background: #ffc55f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.map-circle img {
  height: 16px;
  width: 16px;
  transform: rotate(-20deg);
}
.mapStyle {
  width: 450px;
  height: 350px;
}
.map-info {
  min-height: 100px;
  padding: 10px;
  width: 210px;
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
  position: absolute;
  z-index: 9999;
  transform: translate3d(-40%, -0%, 0);
  cursor: pointer;
  font-size: 12px;
}
.close {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 12px;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  margin-left: 10px;
  position: relative;
  z-index: 99999;
  border-bottom: 8px solid var(--white);
}

@media only screen and (max-width: 767px) {
  .mapStyle {
    width: 290px;
    height: 350px;
  }
}
