@import url("./colorConstant.css");

.accordion {
  width: 420px;
}

.item {
  background: var(--white);
  margin-bottom: 5px;
  padding: 10px 0px;
  width: 400px;
  border-bottom: 1pt solid var(--smokeyGrey);
}
.title {
  color: var(--darkGrey);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}
.title-primary {
  font-weight: 600;
  font-size: 16px;
}
.content {
  color: var(--black);
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  cursor: pointer;
}
.content-icon {
  display: inline-block;
  background: #ffc55f;
  padding: 6px;
  border-radius: 50%;
  width: 28px;
}
.content-address {
  width: 40%;
}
.content-icon img {
  display: block;
  margin: 0 auto;
}
.content.show {
  height: auto;
  max-height: 500px;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  cursor: pointer;
}
.loadmore {
  display: block;
  margin: 0 auto;
  margin-top: 10%;
  background: var(--white);
  border: 2px solid var(--orange);
  font-size: 14px;
}
.loadmore:not(:disabled):not(.disabled):active:focus {
  outline: none;
  box-shadow: none;
}
.alertLocationError {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
  padding: 8px 35px 8px 14px;
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  border: 1px solid #fbeed5;
  border-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .item {
    width: 290px;
  }
}
