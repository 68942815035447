:root {
  --white: #fff;
  --black: #000;
  --footerTitle: rgba(30, 30, 30, 0.8);
  --footerList:rgba(30, 30, 30, 0.8);
  --footerListItemInfo: rgba(30, 30, 30, 0.5);
  --darkJungleGreen: #1e1e1e;
  --smokeyGrey: #707070;
  --darkGrey: #333;
  --orange: #FFC55F;
  
}