@import url("./colorConstant.css");

.footer-center {
  display: flex;
  justify-content: space-around;
}
.footer-title {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--footerTitle);
  margin-bottom: 24px;
}
.footer-list-item {
  list-style: none;
  font-weight: 700;
  font-size: 16px;
  color: var(--footerList);
}
.footer-list-item-info {
  list-style: none;
  font-size: 16px;
  color: var(--footerListItemInfo);
}
.footer-list-item-info a {
    color: var(--footerList);
    text-decoration: none;
}
.footer-list {
  padding: 0;
}
.footer-list-social {
  display: flex;
  padding: 0;
}
.footer-list-social-item {
  list-style: none;
  margin-right: 4px;
}
.footer-list-social-item a {
  text-decoration: none;
}
.footer-copyright {
  padding: 16.2px 14.4px 12.6px;
  border-top: 1px solid var(--darkJungleGreen);
}
.copyright-bottom-text {
  margin: 40pt 0pt;
  text-align: center;
  font-weight: 900;
  color: var(--footerTitle);
}
.copyright-bottom-text a {
  color: var(--footerTitle);
  text-decoration: none;
}
.copyright-text {
  font-size: 16px;
  text-transform: uppercase;
  color: var(--smokeyGrey);
}
.text-center {
  text-align: center;
  margin: 0;
}

 @media only screen and (max-width: 576px) {
 .footer-center {
  display: flex;
  justify-content: flex-start;
}
} 